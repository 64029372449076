.inner-container{
    gap: 0.5rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 10px;
    border: 3px solid rgb(63, 59, 59);
    text-align: center;
}
.inner-container .primaryText{
    color: aliceblue;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: black;
}
.inner-container .button{
    background-color: rgb(15, 69, 163);
    border: 2px solid rgb(18, 18, 18);
    box-shadow: var(--shadow);
    border-radius: 10px;
}

