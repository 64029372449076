
.v-container > div {
    flex :1;
}
.v-right {
    gap : 0.5rem;
}
.accordion{
    margin-top: 2rem;
    border: none;
}
.accordiomItem{
    background: whire;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}
.accordianButton{
    background: white;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;

}
.icon{
    padding: 10px;
    background-color: aliceblue;
    border-radius: 5px;
}
.icon svg{
    fill: blue;
}
.accordianButton .primaryText{
    font-size: 1.4rem;
}
.accordianButton{
    box-shadow: var(--shadow);
    border-radius: 6px;
}
@media (max-width: 1024px) {
    .v-container {
      flex-direction: column;
    }
  }
  
  @media (max-width: 768px) {
    .accordionButton .primaryText {
      font-size: 0.8rem;
    }
  }